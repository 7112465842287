import { httpClient, IHttpClient } from '@api'
import { EPaymentMethod } from '@api/types'

import { EPaymentProvider } from '../../stores/types/EPaymentProvider'
import { TNspkBanks } from '../types/TNspkBanks'
import { TPaymentProviderResponse } from '../types/TPaymentProviderResponse'
import { TSellResponse } from '../types/TSellResponse'
import { TSellSenderInfoResponse } from '../types/TSellSenderInfoResponse'

interface ISellApplicationClient {
  getSender(paymentPublicId: string): Promise<TSellSenderInfoResponse>

  getNspkBanks(): Promise<TNspkBanks[]>

  paymentProviders(): Promise<TPaymentProviderResponse>

  sell(
    applicationId: string,
    paymentMethod: EPaymentMethod,
    amount: string,
    currencyISOAlpha3: string,
    receiver: string,
    nspkBankId: string,
    phone: string,
    provider?: EPaymentProvider,
  ): Promise<TSellResponse>
}

const sellApplicationClientFactory = (
  client: IHttpClient,
): ISellApplicationClient => {
  return {
    getSender(sellPublicId: string): Promise<TSellSenderInfoResponse> {
      return client.get(
        `/client/v1/sell-applications/sell/${sellPublicId}/sender`,
      )
    },
    getNspkBanks(): Promise<TNspkBanks[]> {
      return client.get(`/client/v1/sell-applications/nspk-banks`)
    },
    paymentProviders(): Promise<TPaymentProviderResponse> {
      return client.get(`/client/v1/providers?application=sell`)
    },
    sell(
      applicationId: string,
      paymentMethod: EPaymentMethod,
      amount: string,
      currencyISOAlpha3: string,
      receiver: string,
      nspkBankId: string,
      phone: string,
      provider: EPaymentProvider = EPaymentProvider.RT,
    ): Promise<TSellResponse> {
      return client.post(
        `/client/v1/sell-applications/${applicationId}/sell?provider=${provider}`,
        {
          paymentMethod: paymentMethod,
          receiver: receiver,
          phone: phone,
          nspkBankId: nspkBankId,
          sellSum: {
            amount,
            currencyISOAlpha3,
          },
        },
      )
    },
  }
}

export const sellApplicationClient: ISellApplicationClient =
  sellApplicationClientFactory(httpClient)
