import { httpClient, IHttpClient } from '@api'
import { EPaymentMethod } from '@api/types'

import { EPaymentProvider } from '../../stores/types/EPaymentProvider'
import { TPaymentProviderResponse } from '../types/TPaymentProviderResponse'
import { TPaymentReceiverInfoResponse } from '../types/TPaymentReceiverInfoResponse'
import { TTopupResponse } from '../types/TTopupResponse'

interface ITopupApplicationClient {
  topup(
    applicationId: string,
    paymentMethod: EPaymentMethod,
    amount: string,
    currencyISOAlpha3: string,
    sender: string,
    provider: EPaymentProvider,
    phone?: string,
    last4pan?: string,
  ): Promise<TTopupResponse>

  paymentProviders(): Promise<TPaymentProviderResponse>

  paymentReceiver(
    paymentPublicId: string,
  ): Promise<TPaymentReceiverInfoResponse>

  confirmPayment(invoiceId: number): Promise<void>

  reject(publicPaymentId: string): Promise<void>
}

const topupApplicationClientFactory = (
  client: IHttpClient,
): ITopupApplicationClient => {
  return {
    topup(
      applicationId: string,
      paymentMethod: EPaymentMethod,
      amount: string,
      currencyISOAlpha3: string,
      sender: string,
      provider: EPaymentProvider,
      phone?: string,
      last4pan?: string,
    ): Promise<TTopupResponse> {
      return client.post(
        `/client/v1/topup-applications/${applicationId}/topup?provider=${provider}`,
        {
          paymentMethod: paymentMethod,
          sender: sender,
          phone: phone,
          last4pan: last4pan,
          topupSum: {
            amount,
            currencyISOAlpha3,
          },
        },
      )
    },
    paymentProviders(): Promise<TPaymentProviderResponse> {
      return client.get(`/client/v1/providers?application=purchase`)
    },
    paymentReceiver(
      paymentPublicId: string,
    ): Promise<TPaymentReceiverInfoResponse> {
      return client.get(
        `/client/v1/topup-applications/payments/${paymentPublicId}/receiver`,
      )
    },
    confirmPayment(invoiceId: number): Promise<void> {
      return client.post(
        `/client/v1/topup-applications/invoices/${invoiceId}/confirm-payment`,
      )
    },
    reject(publicPaymentId: string): Promise<void> {
      return client.post(
        `/client/v1/topup-applications/payments/${publicPaymentId}/reject`,
      )
    },
  }
}

export const topupApplicationClient: ITopupApplicationClient =
  topupApplicationClientFactory(httpClient)
